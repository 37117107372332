<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <b-button
        class="btn btn-gradient-primary mb-1 mr-50"
        @click="$router.go(-1)"
      >
        <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
      </b-button>
      <div class="row">
        <div class="col-12">
          <div class="dashboard card rounded-0">
            <div>
              <div
                class="header-cardx p-2 d-flex justify-content-between align-items-center"
              >
                <h3>{{ $t('details') }} {{ items.itemnumber_text }}</h3>

                <b-link :to="{name : 'goodsconfirm'}">
                  {{ $t('key-126') }}
                </b-link>
              </div>

              <div class="p-2">
                <div class="row">
                  <div class="col-6 border-right">
                    <div class="d-flex justify-content-between align-items-center my-75">
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('orderNumber') }}
                      </p>

                      <p class="txt-fexaw mr-1 mb-0 text-primary">
                        {{ items.itemnumber_text }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between align-items-center my-75">
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('parcelCode') }}
                      </p>

                      <p class="txt-fexaw mr-1 mb-0">
                        {{ items.postnumber }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between align-items-center my-75">
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('status') }}
                      </p>
                      <p
                        v-if="items.statusconfirm == 0"
                        class="txt-fexaw mr-1 mb-0 text-secondary"
                      >
                        {{ $t('cancelled') }}
                      </p>
                      <p
                        v-if="items.statusconfirm == 1"
                        class="txt-fexaw mr-1 mb-0 text-danger"
                      >
                        {{ $t('noOwner') }}
                      </p>
                      <p
                        v-if="items.statusconfirm == 2"
                        class="txt-fexaw mr-1 mb-0 text-info"
                      >
                        {{ $t('pendingReview') }}
                      </p>
                      <p
                        v-if="items.statusconfirm == 3"
                        class="txt-fexaw mr-1 mb-0 text-success"
                      >
                        {{ $t('operationCompleted') }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between align-items-center my-75">
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('transactionDate') }}
                      </p>

                      <p class="txt-fexaw mr-1 mb-0">
                        {{ time(items.created_at) }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between align-items-center my-75">
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('details') }}
                      </p>

                      <p class="txt-fexaw mr-1 mb-0">
                        {{ items.remark }}
                      </p>
                    </div>
                    <div
                      v-if="items.statusconfirm != 1"
                      class="d-flex justify-content-between align-items-center my-75"
                    >
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('lotSequence') }}
                      </p>

                      <p class="txt-fexaw mr-1 mb-0">
                        {{ items.lot_name }} / {{ items.lot_order }}
                      </p>
                    </div>

                    <div class="my-2">
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('key-127') }}
                      </p>

                      <p class="txt-fexaw mr-1 mb-0 mt-75">
                        <app-timeline>
                          <app-timeline-item
                            v-if="items.come_thaiwarehouse"
                            :title="$t('key-178')"
                            :time="items.come_thaiwarehouse"
                            variant="success"
                          />

                          <app-timeline-item
                            v-if="items.out_chinesewarehouse"
                            :title="$t('exitWarehouse')"
                            :time="items.out_chinesewarehouse"
                            variant="success"
                          />

                          <app-timeline-item
                            v-if="items.come_chinesewarehouse"
                            :title="$t('arrivedChinaWarehouse')"
                            :time="items.come_chinesewarehouse"
                            variant="success"
                          />
                        </app-timeline>
                      </p>
                    </div>
                    <validation-observer
                      v-if="items.statusconfirm == 1"
                      ref="simpleRules"
                    >
                      <div class="my-4">
                        <p class="txt-fxwrt mr-1 mb-0">
                          {{ $t('key-37') }}
                        </p>

                        <validation-provider
                          #default="{ errors }"
                          name="File"
                          rules="required"
                        >
                          <b-form-file
                            id="h-file1"
                            v-model="fileadmins"
                            accept=".jpg, .png, .jpeg"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            :state="errors.length > 0 ? false:null"
                            @change="fileImage"
                          />
                          <div
                            v-if="filecus"
                            class="my-2 mb-2 preview-image-containerv2 mr-2 d-flex"
                          >
                            <img
                              :src="filecus"
                              alt="Preview"
                              class=""
                              @click="showImage(filecus)"
                            >
                            <div class="preview-image-overlays p-0">
                              <i
                                class="fas fa-times-circle cursor-pointer"
                                @click="deleteImageorder(filecus)"
                              />
                            </div>

                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </validation-observer>
                    <div
                      v-if="items.statusconfirm != 1"
                      class="my-4"
                    >
                      <p class="txt-fxwrt mr-1 mb-0">
                        {{ $t('key-37') }}
                      </p>

                      <div
                        v-if="items.filecus"
                        class="my-2 mb-2 preview-image-containerv2 mr-2 d-flex"
                      >
                        <img

                          :src="items.filecus"
                          alt="Preview"
                          class=""
                          @click="showImage(items.filecus)"
                        >

                      </div>
                    </div>

                  </div>

                  <div class="col-6 my-0">
                    <p class="txt-fxwrt mr-1 mb-0">
                      {{ $t('attachmentList') }}
                    </p>
                    <div class="d-flex align-items-center my-75">
                      <div
                        v-for="(item,index) in items.fileadmin"
                        :key="index"
                        class="mr-50 img-ifxz-cont"
                      >
                        <img
                          :src="item"
                          alt="imgafe"
                          class="img-fluid img-ifxz"
                          @click="showImage(item)"
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-12 newUsers text-center mt-2">
                    <button
                      v-if="items.statusconfirm == 1"
                      class="next-btn next-btn-primary next-btn-medium active-btn"
                      @click="confirmProduct()"
                    >
                      {{ $t('key-128') }}
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal
      id="show-image"
      title="รูปภาพ"
      ok-title="ปิด"
      cancel-title="ยกเลิก"
    >
      <img
        :src="img"
        alt="imgafe"
        class="img-fluid"
      >
    </b-modal> -->

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
      
    />
  </div>
</template>

<script>
import {
  BModal,
  BLink,
  BFormFile,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { required } from '@validations'
import moment from 'moment-timezone'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  name: 'CustomerNewView',
  components: {
    AppTimeline,
    AppTimelineItem,
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    // vSelect,
    BModal,
    BLink,
    BFormFile,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      // Validation
      required,
      code: null,
      file: null,
      img: null,
      items: [
        // { id: 1, img: 'https://plmcdn.azureedge.net/upload/images/goodsconfirm/78cfb13a.jpg' },
        // { id: 2, img: 'https://plmcdn.azureedge.net/upload/images/goodsconfirm/c876f81c.jpg' },
      ],

      // Add Address
      Address: [],
      fullName: null,
      phoneNumber: null,
      address: null,
      province: null,
      district: null,
      subDistrict: null,
      postalCode: null,
      ID: this.$route.params.id,
      filecus: null,
      fileadmins: null,

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    time(data) {
      moment.locale('th')
      return moment(data).format('DD MMMM YYYY HH:mm:ss')
    },
    confirmProduct() {
      // alert
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal.msgBoxConfirm('ยืนยันความเป็นเจ้าของสินค้า?', {
            title: 'ยืนยัน',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'ยืนยัน',
            cancelTitle: 'ยกเลิก',
            footerClass: 'p-25',
            hideHeaderClose: false,
            centered: true,
          })
            .then(value => {
              if (value) {
                const obj = {
                  // eslint-disable-next-line no-underscore-dangle
                  id: this.items._id,
                  filecus: this.filecus,
                }
                this.$http.post('/Goodsconfirm/updateCus', obj)
                  .then(() => {
                    this.Success('บันทึกข้อมูลเรียบร้อย')
                    this.$router.push({ name: 'goodsconfirm' })
                  })
                // console.log(obj)
                // this.$router.push({ name: 'goodsconfirm' })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    async getData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/Goodsconfirm/getDataOne', { params })
        this.items = res
        console.log(res)
      } catch (err) {
        this.SwalError(err)
      }
    },
    fileImage(event) {
      this.fileadmins = event.target.files
      // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < this.fileadmins.length; i++) {
      const filepath = this.fileadmins[0]

      const reader = new FileReader()
      if (!this.fileadmins[0]) {
        this.filecus = null
      } else {
        reader.onload = e => {
          this.filecus = e.target.result
        }
        reader.readAsDataURL(filepath)
      }

      // }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    deleteImageorder() {
      this.filecus = null
      this.fileadmins = null
    },
    showImage(picture) {
      // this.$bvModal.show('show-image')
      // this.img = img
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    </style>
